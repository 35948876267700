// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.SENTRY_RELEASE || 'development',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['simonsays-api.storylines.video', 'dev-api.storylines.video', /^\//],
    }),
  ],
  ignoreErrors: ['Destination project does not exist for this user'],
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
